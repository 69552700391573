.projects-card {
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80%;
  max-width: 600px;
  transition: transform 0.3s;
}

.projects-card:hover {
  transform: scale(1.05);
}

.coming-soon-gif {
  max-width: 100%;
  margin: 20px 0;
  transition: transform 0.3s;
}

.coming-soon-gif:hover {
  transform: rotate(-10deg);
}
