@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");

body {
  background-color: #f0f0f0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

h1,
h2,
h3 {
  color: #333;
  text-align: center;
}

button,
a {
  cursor: pointer;
  text-decoration: none;
}
