@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap);
body {
  background-color: #f0f0f0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

h1,
h2,
h3 {
  color: #333;
  text-align: center;
}

button,
a {
  cursor: pointer;
  text-decoration: none;
}

.profile-card {
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80%;
  max-width: 600px;
  transition: transform 0.3s;
}

.profile-card:hover {
  transform: scale(1.05);
}

.profile-img-container {
  display: flex;
  justify-content: center;
}

.profile-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-info {
  margin-top: 20px;
}

.coffee-button {
  display: block;
  margin: 20px auto;
}

.social-buttons {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #0078d4;
  color: white;
  transition: background-color 0.3s, transform 0.3s;
}

.btn:hover {
  background-color: #005a9e;
  transform: scale(1.1);
}

.certificate-card {
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80%;
  max-width: 600px;
  position: relative;
}

.certificate-card h2 {
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
}

.certificate-carousel {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%;
}

.certificate-item {
  flex: 0 0 auto;
  width: 150px;
  margin: 0 10px;
  text-align: center;
}

.certificate-item img {
  max-width: 100%;
  border-radius: 8px;
}

.certificate-item p {
  margin-top: 8px;
  font-size: 14px;
  color: #555;
}

/* Arrows */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.arrow:hover {
  opacity: 1;
}

.left-arrow {
  left: -20px; /* Adjust to position near the card edge */
}

.right-arrow {
  right: -20px; /* Adjust to position near the card edge */
}

@media (max-width: 768px) {
  .arrow {
    display: none;
  }

  .certificate-carousel {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .certificate-card {
    max-width: 90%;
    margin: auto;
  }
}

.projects-card {
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80%;
  max-width: 600px;
  transition: transform 0.3s;
}

.projects-card:hover {
  transform: scale(1.05);
}

.coming-soon-gif {
  max-width: 100%;
  margin: 20px 0;
  transition: transform 0.3s;
}

.coming-soon-gif:hover {
  transform: rotate(-10deg);
}

