.profile-card {
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80%;
  max-width: 600px;
  transition: transform 0.3s;
}

.profile-card:hover {
  transform: scale(1.05);
}

.profile-img-container {
  display: flex;
  justify-content: center;
}

.profile-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-info {
  margin-top: 20px;
}

.coffee-button {
  display: block;
  margin: 20px auto;
}

.social-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #0078d4;
  color: white;
  transition: background-color 0.3s, transform 0.3s;
}

.btn:hover {
  background-color: #005a9e;
  transform: scale(1.1);
}
